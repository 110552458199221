import ConferenceN from "../../components/conference-detail/ConferenceMT";
import Intro from "../../components/intro/Intro";

export default function DetailN() {
  return (
    <main>
      <Intro />
      <ConferenceN />
    </main>
  );
}
