import Intro from "../../components/intro/Intro";
import Payments from "../../components/payments/PaymentsNW2";

const PaymentNW2 = () => {
    return (
      <main>
        <Intro />
        <Payments title="Relevant Issues of Teaching Methods" />
      </main>
    );
};

export default PaymentNW2;
