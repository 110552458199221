import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

import committeeData from "../../../dummy-data/committee-data.json";

import "./OrganizingInfo.scss";

const lang = localStorage.getItem("lang");

const OrganizingInfo = () => {
  const { t } = useTranslation();
  return (
    <div className="committee organizing">
      <div className="container">
        <h3 className="committee-title line">{t("organiza")}</h3>
        <div className="committee-inner">
          <ul className="committee-list">
            {committeeData[lang].map((item) => (
              <div>
                <h2 className="">{item.title}</h2>

                <ul>
                  {item.content.map((person) => (
                    <li className="committee-list__item" key={uuidv4()}>
                      {person.director && <p>{person.director}</p>}
                      <p>{person?.name}</p>
                      <p>{person?.position}</p>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OrganizingInfo;
