import Intro from "../../components/intro/Intro";
import Payments from "../../components/payments/PaymentyNW1";

const PaymentNW1 = () => {
    return (
        <main>
            <Intro />
            <Payments title="Innolearning-2024" />
        </main>
    );
};

export default PaymentNW1;
